import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import styled from "styled-components";

const MenuContainer = styled.div`
  position: absolute;
  top: 6%;
  right: 10%;
  padding: 7px;
  color: white;
  background-color: #003870;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #003870;
  font-size: 1.2em;

  @media screen and (max-width: 600px) {
    right: 9%;
    top: 1%;
  }
`;

const MenuItem = styled.p`
  margin: 20px 0;
  padding: 2px;
  color: white;
  box-shadow: 0px 0px 2px white;
  text-align: left;
`;

const MenuLink = styled.a`
  text-decoration: none; 
  color: inherit;
`;

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <MenuContainer>
      {!menuOpen && <FiMenu size={26} onClick={toggleMenu} />}
      {menuOpen && <FiX size={26} onClick={toggleMenu} />}
      {menuOpen && (
        <div>
          <MenuItem>
            <MenuLink href="#"> Conheça o projeto</MenuLink>
          </MenuItem>
          <MenuItem>
            <MenuLink href="#about"> Sobre a organização</MenuLink>
          </MenuItem>
          <MenuItem>
              <MenuLink href="#questions"> Perguntas frequentes</MenuLink>
          </MenuItem>

          <MenuItem>
              <MenuLink href="https://www.instagram.com/marilenemartinsda"> Instagram</MenuLink>
          </MenuItem>

          <MenuItem>
              <MenuLink href="https://youtube.com/@ProjetoResgate-wq4ef?si=RImKdSO4AorpN1W-"> Youtube</MenuLink>
          </MenuItem>
        </div>
      )}
    </MenuContainer>
  );
};

export default Menu;
