import React from "react";
import "./App.css";
import Home from "./pages/Home";
import GlobalStyles from "./styles/GlobalStyles";
import ScrollToTopButton from "./components/ScrollToTopButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <div className="App">
      <GlobalStyles  />
      <Home />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
