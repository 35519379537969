import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import testemunho1 from "./../assets/test1.png";
import testemunho2 from "./../assets/test2.png";
import testemunho3 from "./../assets/test3.png";
import styled from "styled-components";
import { Title } from "../styles/components/Testimonials";

const StyledSlider = styled(Slider)`
  max-width: 400px;
  margin: 2% auto; /* Alinha o slider ao centro */
  height: auto;
`;

const Carousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Ativa a passagem automática dos slides
    autoplaySpeed: 4000 // Define a velocidade de passagem automática dos slides em milissegundos
  };

  return (<>
    <Title>Testemunhos</Title>
    <StyledSlider {...settings}>
      <img src={testemunho1} alt="Testemunho 1" />
      <img src={testemunho2} alt="Testemunho 2" />
      <img src={testemunho3} alt="Testemunho 3" />
    </StyledSlider></>
  );
};

export default Carousel;
