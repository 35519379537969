import styled from "styled-components";

export const CarouselImage = styled.img`
  width: 200px;
  height: auto;
`;

export const Title = styled.div`
  font-size: 24px;
  padding-top: 1em;
  font-weight: normal;
  align-items: center;
`;
