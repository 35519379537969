import styled from "styled-components";

export const Section5Container = styled.section`
background: #fff;
  padding: 1% 10%;
  text-align: justify;
  display: flex;
  flex-direction: column; 
  align-items: center; 
  max-width: 100vwx;
  margin: 0 auto; 
  text-align: center;
`;

export const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const TextContainer = styled.div`
  width: 100%;
 max-width: 800px;
`;

export const Paragraph = styled.p`
margin-top: 10px; 
  font-size: 18px;
  margin-bottom: 20px;  
`;


export const Button = styled.button`
  background-color: transparent;
  color: #003870;
  padding: 8px 16px; 
  border: 2px solid #003870;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;

  a {
    text-decoration: none; 
    color: inherit; 
  }
`;
