import React from "react";
import {
  Section3Container,
  Title,
  Paragraph,
  List,
  ListItem,
  Button,
  ListItemTextBackground,
  ListItemText,
} from "../styles/components/Section3Styles";

const Section3 = () => {
  // Array de caminhos das imagens
  const images = [
    require("./../assets/promove/1.jpg"),
    require("./../assets/promove/2.jpg"),
    require("./../assets/promove/3.jpg"),
    require("./../assets/promove/4.jpg"),
    require("./../assets/promove/5.jpg"),
    require("./../assets/promove/6.jpg"),
    require("./../assets/promove/7.jpg"),
    require("./../assets/promove/8.jpg"),
    require("./../assets/promove/9.jpg"),
    require("./../assets/promove/10.jpg"),
    require("./../assets/promove/11.jpg"),
  ];

  // Rótulos correspondentes aos cards
  const labels = [
    "Auditório para palestras",
    "Refeitório com banheiro",
    "Dormitórios com banheiro",
    "Cozinha",
    "Espaço para Esporte/Lazer",
    "Piscina",
    "Escritório",
    "Horticultura",
    "Criação de aves",
    "Cozinha para cursos de panificação",
    "Sala para cursos profissionalizantes",
  ];

  return (
    <Section3Container>
      <Title>
        Desde 2023 estou trabalhando e juntando recursos para esta obra e quero
        convidar você pessoa enviada por Deus para apoiar o projeto Resgate. A
        sua contribuição será apoio na aquisição do terreno e a construção da
        casa, que oferecerá um ambiente que inclui:
      </Title>
      <Paragraph>
        <List>
          {/* Renderizando dinamicamente cada ListItem com sua respectiva imagem e rótulo */}
          {images.map((image, index) => (
           <ListItem key={index} style={{ backgroundImage: `url(${image})`, height: '200px', backgroundSize: 'cover' }}>
           <ListItemTextBackground> {/* Fundo azul */}
             <ListItemText>{labels[index]}</ListItemText> {/* Texto */}
           </ListItemTextBackground>
         </ListItem>
         
          
          ))}
        </List>
      </Paragraph>
      <Button><a href="https://www.vakinha.com.br/4606703" target="__blank">SALVE VIDAS</a></Button>
    </Section3Container>
  );
};

export default Section3;

