import styled from 'styled-components';

export const Section3Container = styled.section`
  background: linear-gradient(to bottom, #ffffff, #003870);
  padding: 3% 5%;
  max-width: 100vw; /* Largura máxima da seção */
  margin: 0 auto; /* Centralizar na tela */
`;

export const Title = styled.h2`
  font-size: 20px;
  margin-bottom: 20px;
  text-align: justify;
  font-weight: 500;
`;

export const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const ListItem = styled.li`
  position: relative; /* Para posicionar corretamente o fundo */
  font-size: 18px;
  margin-bottom: 20px;
  width: calc(33.33% - 10px); /* Ajustando para 3 cards por linha */
  height: 300px; /* Altura dos cards */
  padding: 1em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
  color: #fff;
  font-weight: semi-bold;

  @media screen and (max-width: 768px) {
    width: 100%; /* Alterando para uma coluna em dispositivos menores */
  }

  &:hover {
    transform: scale(1.05);
  }
`;

export const ListItemTextBackground = styled.span`
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: #003870; /* Cor de fundo azul */
  border-radius: 5px;
`;

export const ListItemText = styled.span`
  margin-left: 10px; 
`;


export const Button = styled.button`
  background-color: transparent;
  color: #fff;
  padding: 8px 16px;
  border: 1px solid #fff;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 1em;

  a {
    text-decoration: none; 
    color: inherit; 
  }
`;
