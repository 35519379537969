import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const HeaderSection1Container = styled.div`
  background: #003870;
  position: relative;
  animation: ${fadeIn} 1.2s ease forwards;
`;

export const HeaderContainer = styled.header`
  color: #003870;
  padding: 1% 5%;
  max-width: 100vw;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const Logo = styled.img`
  width: auto;
  height: 100px;
  background: white;
  border-radius: 3px;
  margin-left: 5%;

  @media screen and (max-width: 600px) {
    height: 55px;
  }
`;

export const Section1Container = styled.section`
  position: relative;
  padding: 1% 3%;
  max-width: 100vw;
  margin: 0 auto;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 2%;
  color: white;
`;

export const Content = styled.div`
  max-width: 800px;
`;

export const Title = styled.h2`
  font-size: 36px;
  margin-bottom: 20px;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    font-size: 31px;
  }
`;

export const Paragraph = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
  text-align: justify;
`;

export const Video = styled.video`
  width: 100%;
  height: auto;
`;