import styled from "styled-components";

export const FloatingButton = styled.a`
position: fixed;
bottom: 20px;
right: 20px;
background-color: #25d366; 
color: #fff;
padding: 10px 10px;
border: none;
border-radius: 50%;
cursor: pointer;
transition: opacity 0.3s;
opacity: ${({ visible }) => (visible ? 1 : 0)};
text-decoration: none;
`;