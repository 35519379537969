import React from "react";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section5 from "../components/Section5";
import Section6 from "../components/Section6";
import Testimonials from "../components/Testimonials";
import Menu from "../components/Menu";
import HeaderSection1 from "../components/HeaderSection1";

const Home = () => {
  return (
    <div>
      <HeaderSection1 />
      <Menu/>

      <Section2 />
      <Section3 />
      <Testimonials/>
      <Section4 />
      <Section5 />
      <Section6 />
    </div>
  );
};

export default Home;
