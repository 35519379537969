import { React, useEffect, useState } from "react";
import { FloatingButton } from "../styles/components/ScrollToTopButton";
import whatsappIcon from "./../assets/icons8-whatsapp-48.png";

const ScrollToWhatsAppButton = () => {
  const [isVisible, setIsVisible] = useState(true); // Inicializado como true para mostrar o botão imediatamente

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsVisible(scrollTop > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    window.open("https://wa.me/31687376273", "_blank");
  };

  return (
    <FloatingButton
      href="javascript:void(0);"
      visible={isVisible ? "true" : "false"} 
      onClick={handleClick}
    >
      <img src={whatsappIcon} alt="WhatsApp" />
    </FloatingButton>
  );
};

export default ScrollToWhatsAppButton;