import React from "react";
import logoImg from "../assets/logoResgate.png";
import background from "../assets/RESGATANDO_VIDAS.mp4";
import {
  Content,
  ContentContainer,
  HeaderContainer,
  HeaderContent,
  HeaderSection1Container,
  Logo,
  Paragraph,
  Section1Container,
  Title,
  Video,
} from "../styles/components/HeaderSection1";
import triangle from "./../assets/triangulo.png";

const HeaderSection1 = () => {
  return (
    <HeaderSection1Container>
      <HeaderContainer>
        <HeaderContent>
          <Logo src={logoImg} alt="Logo" />
        </HeaderContent>
      </HeaderContainer>
      <Section1Container
        style={{
          backgroundImage: `url(${triangle})`,
          backgroundPosition: "left top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <ContentContainer>
          <Content>
            <Title>CONHEÇA O PROJETO RESGATE</Title>
            <Paragraph>
              Junte-se a nós para construir um refúgio para aqueles que
              precisam! Vamos mudar vidas, transformar histórias e restaurar
              famílias com fé, foco e determinação. O seu apoio fará a diferença
              na vida de dependentes químicos e mulheres em situação de
              vulnerabilidade, através da nossa vida Deus vai resgatar milhões
              de outras vidas.
            </Paragraph>
          </Content>
          <Video controls autoPlay={true} preload="metadata">
            <source src={background} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        </ContentContainer>
      </Section1Container>
    </HeaderSection1Container>
  );
};

export default HeaderSection1;
