import styled from 'styled-components';
import { FaRegUser, FaRegHeart, FaRegSmile, FaRegStar } from 'react-icons/fa'; 
import { GiBrain } from "react-icons/gi";

export const Section2Container = styled.section`
  background-color: #fff; 
  color: #000;
  padding: 3% 5%; 
  text-align: center;
  max-width: 100vw; 
  margin: 0 auto; 
`;

export const Title = styled.h2`
  font-size: 23px; 
  margin-bottom: 20px; 
  font-weight: 500;
  padding-top: 2%;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; 
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column; 
    align-items: center; 
  }
`;

export const Card = styled.div`
  background-color: #fff;
  border: 3px solid #003870; 
  width: 150px; 
  height: 150px; 
  border-radius: 50%; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1; 
`;

export const Icon = styled.div`
  font-size: 40px;
  margin-bottom: 10px;
  color: #003870;
  &:hover {
    transform: scale(1.19); 
  }
`;

export const ListItem = styled.li`
  font-size: 0.76em; 
  color: #000;
  text-align: center; 
  margin-top: 10px; 
  text-transform: uppercase; 
`;

// Mapeamento de ícones para cada item
export const iconsMap = {
  Identidade: FaRegUser,
  Dignidade: FaRegHeart,
  Autoestima: FaRegSmile,
  Esperança: FaRegStar,
  'Desenvolvimento pessoal': GiBrain,
};
